import { FC } from "react";
import ie from "@assets/windowsIcons/ie-paper.png";
import earth from "@assets/windowsIcons/earth.png";
import { BurnBabySC } from "./BurnBaby.styled";
import BurnEducation from "./BurnEducation";

const BurnBaby: FC = () => {
  return (
    <BurnBabySC>
      <div className="ie__content">
        <div className="ie__content__inner">
          <BurnEducation />
        </div>
      </div>
      <footer className="ie__footer">
        <div className="ie__footer__status">
          <img className="ie__footer__status__img" src={ie} alt="" />
          <span className="ie__footer__status__text">Ready</span>
        </div>
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__right">
          <img className="ie__footer__right__img" src={earth} alt="" />
          <span className="ie__footer__right__text">Burn, Baby, Burn!</span>
          <div className="ie__footer__right__dots" />
        </div>
      </footer>
    </BurnBabySC>
  );
};

export default BurnBaby;
