const File = [
  {
    type: "item",
    disable: true,
    text: "New",
  },
  {
    type: "item",
    disable: true,
    text: "Open...",
  },
  {
    type: "item",
    disable: true,
    text: "Save",
  },
  {
    type: "item",
    disable: true,
    text: "Save As...",
  },
  {
    type: "separator",
  },
  {
    type: "item",
    disable: true,
    text: "Page Setup...",
  },
  {
    type: "item",
    disable: true,
    text: "Print...",
  },
  {
    type: "separator",
  },
  {
    type: "item",
    text: "Exit",
  },
];
const Edit = [
  {
    type: "item",
    disable: true,
    text: "Undo...",
  },
  {
    type: "separator",
  },
  {
    type: "item",
    disable: true,
    text: "Cut",
  },
  {
    type: "item",
    disable: true,
    text: "Copy",
  },
  {
    type: "item",
    disable: true,
    text: "Paste",
  },
  {
    type: "item",
    disable: true,
    text: "Delete",
  },
  {
    type: "separator",
  },
  {
    type: "item",
    disable: true,
    text: "Find...",
  },
  {
    type: "item",
    disable: true,
    text: "Find Next",
  },
  {
    type: "item",
    disable: true,
    text: "Replace...",
  },
  {
    type: "item",
    disable: true,
    text: "Go To...",
  },
  {
    type: "separator",
  },
  {
    type: "item",
    disable: true,
    text: "Select All",
  },
  {
    type: "item",
    text: "Time/Date",
  },
];
const Format = [
  {
    type: "item",
    text: "Word Wrap",
  },
  {
    type: "item",
    disable: true,
    text: "Font...",
  },
];
const View = [
  {
    type: "item",
    disable: true,
    text: "Status Bar",
  },
];
const Help = [
  {
    type: "item",
    disable: true,
    text: "Help Topics",
  },
  {
    type: "item",
    disable: true,
    text: "About Notepepe",
  },
];
const dropDownData = { File, Edit, Format, View, Help };

export default dropDownData;
