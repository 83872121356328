import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  useContract,
  useContractWrite,
  useContractRead,
  useAddress,
  useBalance,
} from "@thirdweb-dev/react";
import Web3Connect from "@components/Web3Connect";
import prebridgeAbi from "./prebridgeABI.json";
import pepecoinABI from "./pepecoinAbi.json";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { addAppWithSettings } from "@store/osState/pepeOSThunk";
import { EAppKeys } from "../appState/EAppKeys";

const formatBalance = (balance?: { displayValue: string }) => {
  if (!balance) return "Loading...";
  const value = Number(balance.displayValue);
  return value.toFixed(2);
};

const BasedStaking: React.FC = () => {
  const [stakeAmount, setStakeAmount] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [dummyState, setDummyState] = useState(0);
  const [creditsEarned, setCreditsEarned] = useState("0");
  const [pepecoinPrebridged, setPepecoinPrebridged] = useState("0");
  const address = useAddress();
  const dispatch = useAppDispatch();

  const basedAddress = process.env.REACT_APP_BASED_CA;
  const stakingContractAddress = process.env.REACT_APP_PREBRIDGE_CA;

  const { data: basedBalance, isLoading: basedBalanceLoading } =
    useBalance(basedAddress);

  const pepeCoinContract = useContract(basedAddress, pepecoinABI);

  const { contract: stakingContract } = useContract(
    stakingContractAddress,
    prebridgeAbi
  );

  const { data: allowance } = useContractRead(
    pepeCoinContract.contract,
    "allowance",
    [address, stakingContractAddress]
  );

  const { data: stakedAmount, isLoading: stakedAmountIsLoading } =
    useContractRead(stakingContract, "getStakedAmount", [
      address,
      basedAddress,
    ]);

  const { data: totalCredits, isLoading: totalCreditsLoading } =
    useContractRead(stakingContract, "calculateCreditsPerToken", [
      address,
      basedAddress,
    ]);

  useEffect(() => {
    if (stakedAmount && stakedAmount._hex) {
      // Convert the hex string to a BigInt
      const stakedBigInt = BigInt(stakedAmount._hex);
      // Format the BigInt as ether
      setPepecoinPrebridged(ethers.utils.formatUnits(stakedBigInt, 18));
    }
  }, [stakedAmount, address, dummyState]);

  useEffect(() => {
    if (totalCredits && totalCredits._hex) {
      // Convert the hex string to a BigInt
      const rewardBigInt = BigInt(totalCredits._hex);
      // Format the BigInt as ether
      setCreditsEarned(ethers.utils.formatUnits(rewardBigInt, 18));
    }
  }, [totalCredits, address, dummyState]);

  const { mutateAsync: approve, isLoading: awaitingApproval } =
    useContractWrite(pepeCoinContract.contract, "approve");

  const { mutateAsync: stake } = useContractWrite(stakingContract, "stake");

  const handleStake = async () => {
    setIsStaking(true);
    try {
      const amountBN = ethers.utils.parseUnits(stakeAmount, 18);

      if (allowance && allowance.lt(amountBN)) {
        setIsApproving(true);
        try {
          await approve({
            args: [stakingContractAddress, amountBN],
          });
          setIsApproving(false);
          setDummyState((prev) => prev + 1);
        } catch (error) {
          console.error("Approval failed:", error);
          dispatch(addAppWithSettings(EAppKeys.Error));
          setIsStaking(false);
          return;
        }
      }

      await stake({
        args: [basedAddress, amountBN],
      });
      dispatch(addAppWithSettings(EAppKeys.SuccessBox));
    } catch (error) {
      console.error("Staking failed:", error);
      dispatch(addAppWithSettings(EAppKeys.Error));
    } finally {
      setIsStaking(false);
    }
  };

  const handleWithdraw = async () => {
    dispatch(addAppWithSettings(EAppKeys.WithdrawDialog));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDummyState((prev) => prev + 1);
    }, 15000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (address) {
      // Fetch balances and rewards when the address changes
      setDummyState((prev) => prev + 1);
    }
  }, [address]);

  return (
    <div>
      <p style={{ marginBottom: "1rem" }}>
        Stake your BasedAI tokens on Ethereum in the AfterBurner and collect
        additional mainnet $BASED at a rate of ~20.06% APY ($BASEDAI will be
        renamed to $BASED at mainnet). All accrued $BASED rewards will be
        available for collection in a single batch on mainnet.
      </p>
      <div className="sections">
        <fieldset>
          <legend>User Information</legend>
          {address ? (
            <>
              <p>
                Connected Address:{" "}
                {address?.slice(0, 8) + "..." + address?.slice(-6)}
              </p>

              <p>BasedAI Available: {formatBalance(basedBalance)}</p>
            </>
          ) : (
            <Web3Connect />
          )}
        </fieldset>
        <fieldset>
          <legend>Stake BasedAI</legend>
          <div className="controls">
            <div>
              <input
                type="text"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                placeholder="Amount to stake"
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleStake();
                }}
                disabled={isStaking || isApproving || awaitingApproval}
              >
                {isStaking ? "Depositing..." : "Stake"}
              </button>
            </div>
            <button
              className="unstyled"
              disabled={basedBalanceLoading}
              onClick={() =>
                setStakeAmount(basedBalance?.displayValue as string)
              }
            >
              Max
            </button>
          </div>
        </fieldset>
        <fieldset>
          <legend>Withdraw BasedAI</legend>
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            {address ? (
              <p>
                {stakedAmountIsLoading ? (
                  "Loading..."
                ) : (
                  <>
                    BasedAI Staked:{" "}
                    {parseFloat(pepecoinPrebridged).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                    })}
                  </>
                )}
              </p>
            ) : (
              "No wallet connected"
            )}
            <div className="controls">
              <div>
                <button
                  id="withdraw-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleWithdraw();
                  }}
                  disabled={parseFloat(pepecoinPrebridged) <= 0}
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
          <p style={{ marginTop: "0.5rem" }}>
            {" "}
            {totalCreditsLoading ? (
              "Loading..."
            ) : (
              <>
                Rewards Accrued:{" "}
                {parseFloat(creditsEarned).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </>
            )}
          </p>
        </fieldset>
      </div>
    </div>
  );
};

export default BasedStaking;
