import React, { FC, useEffect, useState } from "react";
import { BurnEducationSC } from "./BurnBaby.styled";
import educationBG from "@assets/webp/education.webp";
import educationLighting from "@assets/webp/education_lighting.webp";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  pointer-events: none;
`;

const LightingOverlay = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: 3;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

const IframeWrapper = styled.div`
  position: absolute;
  top: 5.5%;
  left: 35%;
  width: 50%;
  height: 50%;
  z-index: 1;
`;

const BurnEducation: FC = () => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIframeLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <BurnEducationSC>
      <BackgroundWrapper>
        <BackgroundImage src={educationBG} alt="Education Background" />
        <LightingOverlay src={educationLighting} alt="Education Lighting" />
        <IframeWrapper>
          {iframeLoaded && (
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/qSccF7P089Y?si=cO6y46KM7lFbrsvW"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          )}
        </IframeWrapper>
      </BackgroundWrapper>
    </BurnEducationSC>
  );
};

export default BurnEducation;
