import React, { FC, useState, useEffect, useRef } from "react";
import pepeHistoryAnimation from "@assets/video/pepe-wing.mp4";
import { HistorySC } from "./History.styled";
import zeroPepeLogo from "@assets/webp/zero-pepe-logo.webp";
import { useAudio } from "../../../../../AudioProvider";

const StartScreen: FC<{ onStart: () => void; onCredits: () => void }> = ({
  onStart,
  onCredits,
}) => {
  return (
    <div className="start-screen">
      <img src={zeroPepeLogo} alt="Zero Pepe Logo" />
      <div className="button-group">
        <button onClick={onStart}>Play Video</button>
        <button onClick={onCredits}>About</button>
      </div>

      <p>
        <span> ORIGINAL EDITION</span>
        <br />
        <br />
        <span> &copy; PEPECOIN EST. 2016</span>
      </p>
    </div>
  );
};

const History: FC = () => {
  const [state, setState] = useState<"start" | "playing" | "credits">("start");
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { fadeOutAudio } = useAudio();

  useEffect(() => {
    if (state === "playing" && videoRef.current) {
      const video = videoRef.current;
      video.play();

      const handleVideoEnd = () => {
        video.currentTime = 0; // reset the video
        setState("start"); // change state back to start
      };

      video.addEventListener("ended", handleVideoEnd);

      // Cleanup function to remove the event listener
      return () => {
        video.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, [state]);

  const startVideo = () => {
    fadeOutAudio("burnAudio", 1500, 100);
    setState("playing");
  };

  return (
    <HistorySC>
      {state === "start" && (
        <StartScreen
          onStart={startVideo}
          onCredits={() => setState("credits")}
        />
      )}
      {state === "playing" && (
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/faIqrFQltf8?si=VyRDvZacNrt0vp0Y"
            title="The Pepening"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          <button onClick={() => setState("credits")}>Learn More</button>
          <button onClick={() => setState("start")}>Go Back</button>
        </div>
      )}
      {state === "credits" && (
        <div className="credits">
          <img src={zeroPepeLogo} alt="Zero Pepe Logo" />

          <video
            id="pepeVideo"
            ref={videoRef}
            src={pepeHistoryAnimation}
            muted={false}
            controls={true}
          />
          <p>
            <span>
              PepeCoin was originally launched in 2016 by a group of gigabrained
              crypto O.G's. Originally it was launched using its own
              Proof-of-Work blockchain network.
            </span>{" "}
            <a
              href="https://x.com/pepecoins/status/711919122012053504?s=20"
              rel="noopener noreferrer"
              target="_blank"
            >
              click here
            </a>{" "}
            <span>
              to see the project's original launch tweet from 2016
              <br />
              <br />
              Due to controversy surrounding the Pepe the Frog meme after the
              2016 presidential election, a handful of exchanges forcefully
              renamed the PepeCoin project with the ticker "Memetic" on their
              platforms to be politically correct.
              <br />
              <br />
              At the time the project was the only other memecoin except for
              Dogecoin and quickly grew in popularity.
              <br />
              <br />
              In 2017 it even attracted the attention of Vitalik Buterin
              himself.{" "}
            </span>
            <a
              href="https://twitter.com/VitalikButerin/status/841882612704653312"
              rel="noopener noreferrer"
              target="_blank"
            >
              click here
            </a>{" "}
            <span>
              to see for yourself.
              <br />
              <br />
              After several years of operating it's own blockchain network, the
              PepeCoin project decided to migrate to the Ethereum blockchain to
              take advantage of EVM smart contracts and DEX platforms where
              their ticker could not be arbitrarily changed.
              <br />
              <br />
              A group of opportunistic individuals saw the Pepecoin migration
              announcements and frontran it by deploying the $PEPE token that
              many of you know today - even going as far as to steal the
              original PepeCoin logo, until they were forced to remove it due to
              copyright.
              <br />
              <br />
              The OG $PEPECOIN contributors were fortunately no strangers to
              injustice. After all, they are battle hardened crypto OGs who
              survived the Great Meme War of 2016. So rather than wallowing in
              their sorrows they embarked on a journey to reclaim what is
              rightfully theirs by devving their way to the top.
              <br />
              <br />
              Since then they have released: <br />
              <br />
              <ul>
                <li>
                  <a
                    href="https://os.pepecoin.io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    PepeOS
                  </a>
                  : A web application inspired by vintage operating systems
                </li>
                <li>
                  Pepe Paint: A unique draw-to-NFT platform and marketplace that
                  empowers artists to create & monetize their talent
                </li>
                <li>
                  <a
                    href="https://kek.bot"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Kek Bot
                  </a>
                  : An advanced AI trading engine that converts natural language
                  into automated trades & rewards users for training it
                </li>
                <li>
                  <a
                    href="https://getbased.ai"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    BasedAI
                  </a>
                  : A custom Layer 1 blockchain network for zk-LLMs and smart
                  contracts
                </li>
                <li>
                  Pepe Messenger: A vintage MSN Messenger themed chat
                  application for decentralized, E2E encrypted, wallet-to-wallet
                  instant messaging
                </li>
                <li>With much more on the way!</li>
              </ul>
              <br />
              <br />
              PepeCoin is throwing a wrench in the well-oiled influencer
              shitcoin factory that churns out copypasta tokens to dump on
              unsuspecting average joes. Instead they are demonstrating that
              Memecoins can and should be coupled with innovation and meaningful
              utility.
              <br />
              <br />
              Now it is up to you to decide what kind of future you want to see.
              You can either support the status quo by keeping the influencer
              assembly line running, or you can be part of history by joining us
              on our journey to becoming the first memecoin to be simultaneously
              considered a top-5 network
              <br />
              <br />
              The #Pepening is invevitable.
            </span>
          </p>
          <button onClick={() => setState("start")}>Back to Start</button>
        </div>
      )}
    </HistorySC>
  );
};

export default History;
