import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import orangeFlame from '@assets/png/Orange_Flame.png';

// Keyframes for explosion effect
const explodeAnimation = keyframes`
  from {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(calc(var(--randomX) * 200px - 100px), calc(var(--randomY) * 200px - 100px)) scale(0.5);
  }
`;

// Styled image with explosion effect
const TrailImage = styled.img<{ explode: boolean }>`
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  width: auto;
  height: 48px;
  transition: all 0.1s ease;

  ${({ explode }) =>
    explode &&
    css`
      animation: ${explodeAnimation} 0.8s ease-out forwards;
    `}
`;

const MouseTrail: React.FC = () => {
  const [trail, setTrail] = useState<
    { x: number; y: number; id: string; img: string; randomX: number; randomY: number; explode: boolean }[]
  >([]);
  const [exploding, setExploding] = useState(false);
  const trailRef = useRef<
    { x: number; y: number; id: string; img: string; randomX: number; randomY: number; explode: boolean }[]
  >([]);
  const lastPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const createUniqueId = useCallback(() => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }, []);

  const updateTrail = useCallback(
    (x: number, y: number, explode = false) => {
      const newImage = {
        x,
        y,
        id: createUniqueId(),
        img: orangeFlame,
        randomX: Math.random(),
        randomY: Math.random(),
        explode,
      };
      trailRef.current = [...trailRef.current.slice(-14), newImage];
      setTrail([...trailRef.current]); // Update the trail in state
    },
    [createUniqueId]
  );

  useEffect(() => {
    let animationFrameId: number;

    const handleMouseMove = (e: MouseEvent) => {
      lastPosition.current = { x: e.clientX, y: e.clientY };
    };

    const animate = () => {
      if (!exploding) {
        const { x, y } = lastPosition.current;
        updateTrail(x, y);
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    window.addEventListener('mousemove', handleMouseMove);
    animationFrameId = requestAnimationFrame(animate);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, [updateTrail, exploding]);

  // Handle double-click event and trigger explosion
  const handleDoubleClick = useCallback(() => {
    // console.log('Double-click detected, triggering explosion animation');

    setTrail((prevTrail) =>
      prevTrail.map((item) => ({
        ...item,
        explode: true, // Mark all trail items as exploding
      }))
    );

    setExploding(true);

    // After explosion, reset trail to current mouse position
    setTimeout(() => {
      setExploding(false);
      setTrail([]); // Clear trail after explosion
      const { x, y } = lastPosition.current; // Get last mouse position
      updateTrail(x, y); // Restart the trail from the current mouse position
    }, 800); // Explosion duration matches the animation duration
  }, [updateTrail]);

  useEffect(() => {
    document.addEventListener('dblclick', handleDoubleClick);
    // console.log('Double-click listener added');

    return () => {
      document.removeEventListener('dblclick', handleDoubleClick);
      // console.log('Double-click listener removed');
    };
  }, [handleDoubleClick]);

  return (
    <>
      {trail.map((item, index) => (
        <TrailImage
          key={item.id}
          src={item.img}
          explode={item.explode}
          style={{
            left: `${item.x}px`,
            top: `${item.y}px`,
            opacity: item.explode ? 1 : 1 - index * 0.03,
            '--randomX': item.randomX,
            '--randomY': item.randomY,
          } as React.CSSProperties}
        />
      ))}
    </>
  );
};

export default MouseTrail;
