export enum EAppKeys {
  Tour = "Tour",
  Explorer = "Explorer",
  BasedFarm = "BasedFarm",
  Memesweeper = "Memesweeper",
  Error = "Error",
  Pepemail = "Pepemail",
  Wallet = "Wallet",
  Notepepe = "Notepepe",
  Wenpamp = "Wenpamp",
  PepePaint = "PepePaint",
  Uniswap = "Uniswap",
  Dextools = "Dextools",
  Frogger = "Frogger",
  GTA1 = "GTA1",
  Messenger = "Messenger",
  Zuma = "Zuma",
  KekBot = "KekBot",
  UserPreferences = "UserPreferences",
  SuccessBox = "SuccessBox",
  Information = "Information",
  BurnBaby = "BurnBaby",
  WithdrawDialog = "WithdrawDialog",
  Games = "Games",
  SocialApps = "SocialApps",
  TradingApps = "TradingApps",
}

export enum EAppType {
  Game = "Game",
  Utility = "Utility",
  Social = "Social",
  Based = "Based",
  Trading = "Trading",
  System = "System",
}
