import React, { createContext, useContext, useState } from "react";
import burnStart from "@assets/audio/burn_theme.mp3";
import shadilayBootSound from "@assets/audio/shadilay-boot-sound.mp3";

type AudioContextType = {
  audios: {
    burnAudio: HTMLAudioElement;
    shadilayBoot: HTMLAudioElement;
  };
  play: (key: string) => void;
  pause: (key: string) => void;
  fadeOutAudio: (key: string, duration: number, interval: number) => void;
};

const AudioContext = createContext<AudioContextType>({
  audios: {
    burnAudio: new Audio(),
    shadilayBoot: new Audio(),
  },

  play: () => {},
  pause: () => {},
  fadeOutAudio: () => {},
});

export const useAudio = () => useContext(AudioContext);

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [audios] = useState({
    burnAudio: new Audio(burnStart),
    shadilayBoot: new Audio(shadilayBootSound),
  });

  audios.burnAudio.volume = 0.5;

  const play = (key: string) => {
    const audio = audios[key as keyof typeof audios];
    if (audio) {
      audio.play();
    }
  };

  const pause = (key: string) => {
    const audio = audios[key as keyof typeof audios];
    if (audio) {
      audio.pause();
    }
  };

  const fadeOutAudio = (key: string, duration: number, interval: number) => {
    const audio = audios[key as keyof typeof audios];
    if (audio) {
      const volumeStep = audio.volume / (duration / interval);
      const fadeOutTimer = setInterval(() => {
        if (audio.volume > volumeStep) {
          audio.volume -= volumeStep;
        } else {
          audio.volume = 0;
          pause(key);
          clearInterval(fadeOutTimer);
        }
      }, interval);
    }
  };

  return (
    <AudioContext.Provider value={{ audios, play, pause, fadeOutAudio }}>
      {children}
    </AudioContext.Provider>
  );
};
