import React from "react";
import styled from "styled-components";
import flameGif from "@assets/background/flame_gif.webp";
import MouseTrail from "./MouseTrail";

const FlameContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${flameGif}) repeat-x center;
  background-size: 450px;
  margin-bottom: 200px;
  background-color: black;
  opacity: 1;
  pointer-events: none;
  z-index: -1;

  @media (max-width: 768px) {
    background-size: 300px;
    margin-bottom: 100px;
  }
`;

const FlameBackground: React.FC = () => {
  return (
    <>
      <FlameContainer />
      <MouseTrail />
    </>
  );
};

export default FlameBackground;
