import { thirdwebClient } from "../../client";
import { ethereum } from "thirdweb/chains";
import { getContract } from "thirdweb/contract";
import basedFarmAbi from "../apps/BasedFarm/basedAbi.json";
import pepecoinAbi from "../apps/BasedFarm/pepecoinAbi.json";

export const FOCUSING = {
  WINDOW: "WINDOW",
  ICON: "ICON",
  DESKTOP: "DESKTOP",
};
export const POWER_STATE = {
  START: "START",
  LOG_OFF: "LOG_OFF",
  TURN_OFF: "TURN_OFF",
};

export const isMobile = window.innerWidth < 768;

export const basedFarmContract = getContract({
  client: thirdwebClient,
  chain: ethereum,
  address: process.env.REACT_APP_BASED_FARM_CA as string,
  abi: basedFarmAbi as any,
});

export const pepecoinContract = getContract({
  client: thirdwebClient,
  chain: ethereum,
  address: process.env.REACT_APP_PEPECOIN_CA as string,
  abi: pepecoinAbi as any,
});
