import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { BasedFarmSC } from "./BasedFarm.styled";
import Prebridge from "./Prebridge";
import pepeStar from "@assets/pepeOS-icons/Pepe_Star.png";
import prebridgeAbi from "./prebridgeABI.json";
import BasedStaking from "./BasedStaking";
import pepecoinLogo from "@assets/png/pepecoin-logo.png";
import basedBrain from "@assets/webp/based-pixel.webp";
import prebridgeCredits from "./stakers_credits.json";
import CreditsStaking from "./CreditsStaking";

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

const BasedFarm: FC = () => {
  const [creditsEarned, setCreditsEarned] = useState("0");
  const [dummyState, setDummyState] = useState(0);
  const [activeTab, setActiveTab] = useState<
    "stake" | "bridge" | "credits" | "about"
  >("bridge");
  const [prebridgeBalance, setPrebridgeBalance] = useState(0);

  const address = useAddress();

  const { contract: stakingContract } = useContract(
    process.env.REACT_APP_PREBRIDGE_CA,
    prebridgeAbi
  );

  const { data: totalCredits, isLoading: totalCreditsLoading } =
    useContractRead(stakingContract, "calculateTotalCredits", [address]);

  // TODO: Improve this when ready

  useEffect(() => {
    if (totalCredits && totalCredits._hex) {
      // Convert the hex string to a BigInt
      const rewardBigInt = BigInt(totalCredits._hex);
      // Format the BigInt as ether
      const rewardEther = ethers.utils.formatUnits(rewardBigInt, 18);

      // Find the matching address entry in the pre-imported JSON data
      const userCredits = prebridgeCredits.find(
        (entry) => entry.address === address
      );
      if (userCredits) {
        setPrebridgeBalance(parseFloat(userCredits.totalCreditsEther));
        // Calculate totalReward
        const totalReward =
          parseFloat(rewardEther) + parseFloat(userCredits.totalCreditsEther);
        // Conditionally set credits earned based on comparison
        if (
          parseFloat(rewardEther) > parseFloat(userCredits.totalCreditsEther)
        ) {
          setCreditsEarned(rewardEther);
        } else {
          setCreditsEarned(totalReward.toString());
        }
      } else {
        setPrebridgeBalance(0);
        // If no matching address, use the original rewardEther
        setCreditsEarned(rewardEther);
      }
    }
  }, [totalCredits, address, dummyState]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDummyState((prev) => prev + 1);
    }, 15000);

    return () => clearInterval(intervalId);
  }, []);

  const refreshRewards = useCallback(() => {
    setDummyState((prev) => prev + 1);
  }, []);

  useInterval(refreshRewards, 13000);

  return (
    <BasedFarmSC>
      <div className="banner">
        <h3>
          BasedAI
          <br />
          AfterBurner
        </h3>
      </div>
      <menu role="tablist">
        <button
          role="tab"
          aria-controls="bridge"
          onClick={() => setActiveTab("bridge")}
          style={{ display: "flex", gap: "5px", alignItems: "center" }}
        >
          <img
            style={{
              height: "1rem",
            }}
            src={pepecoinLogo}
            alt="PepeCoin Logo"
          />{" "}
          $PEPECOIN
        </button>
        <button
          role="tab"
          aria-controls="stake"
          onClick={() => setActiveTab("stake")}
        >
          <img
            style={{
              height: "1rem",
            }}
            src={basedBrain}
            alt="Based Brain Logo"
          />{" "}
          $BASED
        </button>
        <button
          role="tab"
          aria-controls="credits"
          onClick={() => setActiveTab("credits")}
        >
          Brain Credits
        </button>

        <button
          role="tab"
          aria-controls="about"
          onClick={() => setActiveTab("about")}
        >
          About BasedAI
        </button>
      </menu>
      <article role="tabpanel" id="stake" hidden={activeTab !== "stake"}>
        <BasedStaking />
      </article>
      <article role="tabpanel" id="bridge" hidden={activeTab !== "bridge"}>
        <Prebridge />
      </article>
      <article role="tabpanel" id="credits" hidden={activeTab !== "credits"}>
        <CreditsStaking />
      </article>
      <article role="tabpanel" id="about" hidden={activeTab !== "about"}>
        <p style={{ marginBottom: "1rem" }}>
          BasedAI is a custom engineered Layer 1 network being developed by the
          gigabrains at PepeCoin and Based Labs.
          <br />
          <br />
          BasedAI is a peer-to-peer network for zk-LLMs (Zero Knowledge Large
          Language Models) with full EVM (smart contract) capabilities. The
          network weaves the decentralized storage and computation of open
          source AI models directly into it's consensus mechanism and enables
          users to interact with in a fully privacy-preserving manner.
          <br />
          <br />
          For the layman, BasedAI is a privacy-preserving decentralized AI
          network that democratizes the use and monetization of AI models,
          ushering in a new era of AI freedom that is free of the shackles
          imposed on traditional models by centralized tech overlords.
          <br />
          <br />
          BasedAI is currently undergoing a public testnet phase, and will be
          transitioning to mainnet soon. To participate in the testnet visit{" "}
          <a
            target="_blank"
            href="https://getbased.ai"
            rel="noopener noreferrer"
          >
            {"https://getbased.ai"}
          </a>
          <br />
          <br />
          We look forward to you all joining us on this journey.
          <br />
          <br />
          CA: {process.env.REACT_APP_BASED_CA}
        </p>
      </article>
      <section className="tabs">
        <article role="tabpanel">
          <fieldset>
            <legend>Total Rewards</legend>
            <div
              className="controls"
              style={{ flexDirection: "column", gap: "1rem" }}
            >
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <img
                  style={{ height: "1rem" }}
                  src={pepeStar}
                  alt="pepe-star"
                />
                <p>
                  {" "}
                  {totalCreditsLoading ? (
                    "Loading..."
                  ) : (
                    <>
                      Mainnet $BASED Earned:{" "}
                      {parseFloat(creditsEarned).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}
                    </>
                  )}
                </p>
              </div>

              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <p style={{ opacity: 0.7 }}>
                  * Earned from Pre-Bridge:{" "}
                  {prebridgeBalance.toLocaleString("en-US")} (ended)
                </p>
              </div>
            </div>
          </fieldset>
        </article>
      </section>

      <section
        className="field-row"
        style={{ justifyContent: "space-between" }}
      >
        <p style={{ textAlign: "left", fontSize: "0.75rem" }}>
          Forgot to withdraw from the V1 farm?{" "}
          <a
            href="https://forms.simple.ink/pepecoin-v1-pool-claim"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
        </p>
        <button
          onClick={() => window.open("https://www.getbased.ai", "_blank")}
          style={{ minWidth: "110px" }}
        >
          About BasedAI
        </button>
      </section>
    </BasedFarmSC>
  );
};

export default BasedFarm;
