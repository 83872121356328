import commiter from "@assets/team/commiter.jpg";
import jd from "@assets/team/jd.jpg";
import damoos3 from "@assets/team/damoos3.jpeg";
import prometheus from "@assets/team/prometheus.jpg";
import smille from "@assets/team/smille.jpg";
import jpeg0x07041776 from "@assets/team/classypepe.jpeg";
import pepeSpace from "@assets/team/smile-dance.gif";
import musicIcon from "@assets/windowsIcons/690(16x16).png";

enum EProfileNavigation {
  Pepe = "Pepe",
  Prometheus = "Prometheus",
  Dev0x07041776 = "0x07041776",
  Commiter = "Commiter",
  JumpDrive = "Jump Drive",
  Smille = "Smille",
  Damoos3 = "Damoos3",
}

export const teamInfo = [
  {
    profilePicture: pepeSpace,
    name: EProfileNavigation.Pepe,
    status: ":-)",
    role: "King of meme culture",
    location: "Creator: Matt Furie",
    mood: "Feels good man",
    links: {
      twitter: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/512px-Logo_of_Twitter.svg.png",
        url: "https://twitter.com/pepecoins",
      },
      telegram: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/640px-Telegram_2019_Logo.svg.png",
        url: "https://t.me/pepecoins",
      },
      github: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
        url: "https://github.com/memeticofficial",
      },
      music: {
        icon: musicIcon,
        url: "https://www.youtube.com/watch?v=2uRLJZxINAQ",
      },
    },
    interests: {
      general: ["Tendies", "Good Mornings", "O.G.s", "Cobbee", "My Frens"],
      music: ["P.E.P.E - Shadilay"],
      memes: ["Pepe", "Wojak"],
    },
    details: {
      status: "Rare",
      lookingFor: "Autists",
    },
    about: {
      headline: "Feels good man!",
      aboutMe: `In the vast landscape of meme culture, there is no meme beloved more than Pepe the Frog. With limitless creativity inspired by this adorable frog, he has come to embody the spirit of meme culture and brought endless joy and laughter to all those who look upon him. Pepe the Frog has transcended many boundaries and challenges on his path to becoming a canvas of Memes, emotions, ideas and pure love.
      \n
      This lovable frog embraces his role, inviting all who adore him to transform him in an infinite array of artistic settings. Pepe has seen it all, and he couldn't be happier about it.`,
      lookingFor: `To be your fren and bring you memetic power, joy and inspiration.`,
    },
  },
  {
    profilePicture: prometheus,
    name: EProfileNavigation.Prometheus,
    status: "We’re going to shred the competition!",
    role: "Project Lead",
    location: "Visionary of Kek",
    mood: "Focused",
    links: {
      twitter: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/512px-Logo_of_Twitter.svg.png",
        url: "https://twitter.com/_pr0m3theus_",
      },
      telegram: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/640px-Telegram_2019_Logo.svg.png",
        url: "https://t.me/pepecoins",
      },
      github: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
        url: "https://github.com/memeticofficial",
      },
    },
    interests: {
      general: [
        "Petrol head - Loves all things high horsepower.",
        "Making you laugh, fren",
        "Aliens",
      ],
      music: ["Music of all types but Especially Hard Rock & Metal"],
      conspiracies: ["I’m not saying it was aliens, but it was aliens"],
      goals: [
        "Create an authentic PepeCoin powered Web3 ecosystem",
        "Fuse the digital and the tangible with the power of DeFi",
        "Meme things into existence",
        "Make you laugh",
      ],
    },
    details: {
      status: "Autistic",
      lookingFor: "More autists",
    },
    about: {
      headline: "I just want to build cool stuff.",
      aboutMe: `Hey Fren! I’m the Project lead of PepeCoin. I believe in the power of humor and innovation to create a crypto that can be both fun, and feature rich. In my free time, I'm a rock 'n' roll enjoyoor, reveling in the energy of hard rock and metal, and a dedicated petrol head, cherishing all things automobile and high horsepower. I love bringing laughter and happiness to the world. My friends call me "Prometheus" because I bring the fire of technology and the creativity of meme magic to the project.`,
      lookingFor: `What am I looking for? The key to open the door to the memetic realm. Beyond this door lies another dimension of sound, sight, and mind. A realm of substance and shadow, of ideas and their manifestations. A dimension where humor and the powers of meme magic are given to all who enter and fill them with a joy and laughter once forgotten. I believe Pepe the frog, and Pepecoin are that key.`,
    },
  },
  {
    profilePicture: jpeg0x07041776,
    name: EProfileNavigation.Dev0x07041776,
    status: "Revolting somewhere",
    role: "Sekret Dev",
    location: "Democratic People's Republic of Kek (DPRK)",
    mood: "Channeling my inner autism",
    links: {
      twitter: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/512px-Logo_of_Twitter.svg.png",
        url: "https://twitter.com/pepecoins",
      },
      telegram: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/640px-Telegram_2019_Logo.svg.png",
        url: "https://t.me/kekitykek",
      },
      github: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
        url: "https://github.com/memeticofficial",
      },
      music: {
        icon: musicIcon,
        url: "https://www.youtube.com/watch?v=2uRLJZxINAQ",
      },
    },
    interests: {
      general: ["Liberty", "Firearms", "Free Speech"],
      music: ["Smash Mouth", "Nickelback"],
      memes: ["Pepe", "Doge", "Trollface", "Trump Gifs"],
      conspiracies: [
        "J.P. Morgan Sunk the Titanic",
        "Trudeau is Castro's Son",
        "Cold War never ended, commies went underground",
      ],
    },
    details: {
      status: "Autistic",
      lookingFor: "Memes",
    },
    about: {
      headline: "I channel autism through code",
      aboutMe: `Severely autustic developer with over 10 years experience in Cryptocurrency, and even longer in shitposting,  memes, and degeneracy. I am a firm believer in the power of autism, and I channel that power through my code.`,
      lookingFor: `I am looking for other autists to join me in my quest to create a new internet, and to help me spread the word of the coming of the autist revolution.`,
    },
  },
  {
    profilePicture: commiter,
    name: EProfileNavigation.Commiter,
    status: "",
    role: "Gigabrain Dev",
    location: "Republic of Kekistan",
    mood: "Stoic",
    links: {
      twitter: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/512px-Logo_of_Twitter.svg.png",
        url: "https://twitter.com/pepecoins",
      },
      telegram: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/640px-Telegram_2019_Logo.svg.png",
        url: "https://t.me/commiter",
      },
      github: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
        url: "https://github.com/memeticofficial",
      },
      music: {
        icon: musicIcon,
        url: "https://www.youtube.com/watch?v=2uRLJZxINAQ",
      },
    },
    interests: {
      general: ["Software"],
      music: ["Silence"],
      memes: ["Pepe", "Trump Gifs"],
    },
    details: {
      status: "Thinking",
      lookingFor: "Glory",
    },
    about: {
      headline: "In my pond and happy.",
      aboutMe: `Quant finance, cryptography & software engineering background.`,
      lookingFor: `AGI through Based AI.`,
    },
  },
  {
    profilePicture: jd,
    name: EProfileNavigation.JumpDrive,
    status: "Building",
    role: "Dev",
    location: "Kekistan",
    mood: "Moisturized",
    links: {
      twitter: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/512px-Logo_of_Twitter.svg.png",
        url: "https://twitter.com/pepecoins",
      },
      telegram: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/640px-Telegram_2019_Logo.svg.png",
        url: "https://t.me/jump_drive",
      },
      github: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
        url: "https://github.com/memeticofficial",
      },
      music: {
        icon: musicIcon,
        url: "https://www.youtube.com/watch?v=2uRLJZxINAQ",
      },
    },
    interests: {
      general: ["Sportsball"],
      music: ["Taylor Swift"],
      memes: ["Pepe", "Trump Gifs"],
    },
    details: {
      status: "Building",
      lookingFor: "Memes",
    },
    about: {
      headline: "Building Pepecoin & Based AI",
      aboutMe: ``,
      lookingFor: `Pepecoin to be the next world reserve currency.`,
    },
  },
  {
    profilePicture: smille,
    name: EProfileNavigation.Smille,
    status: "Peace begins with a smile.",
    role: "Gud Dev",
    location: "i'm in ur computer upgrading ur RAM",
    mood: "Sleep schedule is fucked but I am otherwise ok",
    links: {
      twitter: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/512px-Logo_of_Twitter.svg.png",
        url: "https://twitter.com/3456smille",
      },
      telegram: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/640px-Telegram_2019_Logo.svg.png",
        url: "https://t.me/smille_1234",
      },
      github: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
        url: "https://github.com/memeticofficial",
      },
      music: {
        icon: musicIcon,
        url: "https://www.youtube.com/watch?v=989-7xsRLR4",
      },
    },
    interests: {
      general: [
        "Solving problems",
        "Databases (seriously, I love them!)",
        "Filling gaps in code",
        "Misery",
        "Rushing things that are past deadline",
        "🙂🙂🙂🙂🙂🙂🙂🙂",
      ],
      memes: ["Pepe", "Doge", "Hide the Pain Harold"],
      conspiracies: ["Everyone on the internet is a bot except for me"],
    },
    details: {
      status: "Autistic",
      lookingFor: "Memes",
    },
    about: {
      headline: "Ok, I figured out how we can do that.",
      aboutMe: `Full-time problem solver, part-time sanity sacrificer, and masochist at your service! I'm a crypto and tech enthusiast with a knack for fixing things, my DMs are always open for commiseration. I spend my days solving other people's problems while creating my own, embracing the workaholic grind and somehow loving every minute of it.`,
      lookingFor: `Peace and quiet.`,
    },
  },
  {
    profilePicture: damoos3,
    name: EProfileNavigation.Damoos3,
    status: "Leading us to the Promised Land!",
    role: "Community Lead",
    location: "",
    mood: "※\\(^o^)/※",
    links: {
      twitter: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/512px-Logo_of_Twitter.svg.png",
        url: "https://twitter.com/damoos3",
      },
      telegram: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/640px-Telegram_2019_Logo.svg.png",
        url: "https://t.me/damoos3",
      },
      github: {
        icon: "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
        url: "https://github.com/memeticofficial",
      },
      music: {
        icon: musicIcon,
        url: "https://www.youtube.com/watch?v=2uRLJZxINAQ",
      },
    },
    interests: {
      general: [
        "Agriculture and farming",
        "Homesteads",
        "Art & Curation",
        "Politics and Memes",
        "History",
        "Web 3 & Crypto",
      ],
    },
    details: {
      status: "Simple man",
      lookingFor: "Tendies",
    },
    about: {
      headline: "youngest oldfeg",
      aboutMe: `Hi, My name is Moosa and I am the community manager for PepeCoin. I handle support and infrastructure, and am the POM POMS behind every community effort. PepeCoin started as just a group of like-minded friends. Throughout the years that commonality kept us together -- a love of Pepe, friends, crypto, art and more! At every turn, I will be there cheering on and leading the community to the promised land of Kek!`,
      lookingFor: `Frens to meme with.`,
    },
  },
];
