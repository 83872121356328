import styled from "styled-components";
import { IEWrapperSC } from "../Uniswap/Uniswap.styled";

export const BurnBabySC = styled(IEWrapperSC)`
  .ie__content__inner {
    background: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BurnEducationSC = styled.div`
  width: 100%;
  height: 100%;
`;
