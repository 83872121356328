import { FC } from "react";

import { KekBotPageSC } from "./KekBotPage.styled";

const KekBotPage: FC = () => {
  return (
    <KekBotPageSC>
      <iframe
        src="https://kek.bot"
        style={{ width: "100%", height: "100%" }}
        frameBorder="0"
        allowFullScreen
        title="KekBot Interface"
      ></iframe>
    </KekBotPageSC>
  );
};

export default KekBotPage;
