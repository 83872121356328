import styled from "styled-components";

export const FullConversationSC = styled.div``;

export const BeginningMessageSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  opacity: 0.6;
`;
